import LineService from '../../services/line.service.js';

const storagedSessions = JSON.parse(localStorage.getItem('sessions.all') || null);
const storagedPerUser = JSON.parse(localStorage.getItem('sessions.per_user') || null);

const initialState = {
  all: storagedSessions,
  per_user: storagedPerUser
}
export const sessions = {
  namespaced: true,
  state: initialState,
  actions: {
    getAll({ commit }) {
      return LineService.getAllSessions()
        .then(async lines => {
          //console.log('line module ', lines)
          commit('getAllSuccess', lines);
          return Promise.resolve(lines);
        },
          async error => {
            commit('getAllFailure');
            return Promise.reject(error);
          })
    },
    getAllPerUser({ commit }, username) {
      return LineService.getAllUserSessions(username)
        .then(async lines => {
          //console.log('line module ', lines)
          commit('getAllPerUserSuccess', lines);
          return Promise.resolve(lines);
        },
          async error => {
            commit('getAllPerUserFailure');
            return Promise.reject(error);
          })
    },
    kill({ commit }, task_id) {
      return LineService.killSession(task_id)
        .then(async response => {
          //console.log('line module ', task_id)
          commit('killSuccess', task_id);
          return Promise.resolve(response);
        },
          async error => {
            commit('killFailure');
            return Promise.reject(error);
          })
    },
    killPerUser({ commit }, task_id) {
      return LineService.killSession(task_id)
        .then(async response => {
          //console.log('line module ', task_id)
          commit('killPerUserSuccess', task_id);
          return Promise.resolve(response);
        },
          async error => {
            commit('killPerUserFailure');
            return Promise.reject(error);
          })
    },
    wakeup({ commit }, sim_id) {
      return LineService.wakeupSim(sim_id)
        .then(async response => {
          //console.log('line module ', response)
          commit('wakeupSuccess');
          return Promise.resolve(response);
        },
          async error => {
            commit('wakeupFailure');
            return Promise.reject(error);
          })
    },
    reload({ commit }, line_id) {
      return LineService.reloadModule(line_id)
        .then(async response => {
          //console.log('line module ', response)
          commit('reloadSuccess');
          return Promise.resolve(response);
        },
          async error => {
            commit('reloadFailure');
            return Promise.reject(error);
          })
    },
    request({ commit }, data) {
      return LineService.requestSession(data)
        .then(async response => {
          //console.log('line module ', response)
          commit('requestSuccess');
          return Promise.resolve(response);
        },
          async error => {
            commit('requestFailure');
            return Promise.reject(error);
          })
    },
  },
  mutations: {
    getAllSuccess(state, lines) {
      state.all = lines;
      localStorage.setItem('sessions.all', JSON.stringify(lines));
    },
    getAllFailure(state) {
      state.all = []
      console.log('get all sessions failure')
    },
    getAllPerUserSuccess(state, lines) {
      state.per_user = lines;
      localStorage.setItem('sessions.per_user', JSON.stringify(lines));
    },
    getAllPerUserFailure(state) {
      state.per_user = []
      console.log('get all sessions failure')
    },
    killSuccess(state, task_id) {
      let index = state.all.findIndex(session => session.task_id == task_id);
      state.all.splice(index, 1);
    },
    killFailure() {
      console.log('kill session failure')
    },
    killPerUserSuccess(state, task_id) {
      let index = state.per_user.findIndex(session => session.task_id == task_id);
      state.per_user.splice(index, 1);
    },
    killPerUserFailure() {
      console.log('kill session failure')
    },
    reloadSuccess(/* state, task_id */) {
      console.log('reload session success')
    },
    reloadFailure() {
      console.log('reload session failure')
    },
    wakeupSuccess(/* state, task_id */) {
      console.log('wakeup session success')
    },
    wakeupFailure() {
      console.log('wakeup session failure')
    },
    requestSuccess() {
      console.log('request session success')
    },
    requestFailure() {
      console.log('request session failure')
    },
  }
};