import UserService from '../../services/user.service.js';

const storagedUsers = JSON.parse(localStorage.getItem('users') || null);
const initialState = {
  user_list: storagedUsers
}
export const users = {
  namespaced: true,
  state: initialState,
  actions: {
    getAll({ commit }) {
      return UserService.getAllUsers()
        .then(async users => {
          //console.log('users module ', users)
          commit('getAllSuccess', users);
          return Promise.resolve(users);
        },
          async error => {
            commit('getAllFailure');
            return Promise.reject(error);
          })
    },
    update({ commit }, user) {
      return UserService.updateUser(user)
        .then(async response => {
          //console.log('users module ', response)
          commit('updateSuccess', user);
          return Promise.resolve(response);
        },
          async error => {
            commit('updateFailure');
            return Promise.reject(error);
          })
    },
    delete({ commit }, username) {
      return UserService.deleteUser(username)
        .then(async response => {
          commit('deleteSuccess', username);
          return Promise.resolve(response);
        },
          async error => {
            commit('deleteFailure');
            return Promise.reject(error);
          })
    },
  },
  mutations: {
    getAllSuccess(state, users) {
      state.user_list = users;
      localStorage.setItem('users', JSON.stringify(users));
    },
    getAllFailure() {
      console.log('get all users failure')
    },
    updateSuccess(state, payload) {
      Object.assign(state.user_list.find(user => user.username === payload.username), payload);
    },
    updateFailure() {
      console.log('update user failure')
    },
    deleteSuccess(state, username) {
      let index = state.user_list.findIndex(user => user.username == username);
      state.user_list.splice(index, 1);
    },
    deleteFailure() {
      console.log('delete user failure')
    },

  }
};