import RoleService from '../../services/role.service.js';

const storagedRoles = JSON.parse(localStorage.getItem('roles') || null);
const initialState = {
    role_list: storagedRoles
}
export const roles = {
    namespaced: true,
    state: initialState,
    actions: {
        getAll({ commit }) {
            return RoleService.getAllRoles()
                .then(async roles => {
                    //console.log('role module ', roles)
                    commit('getAllSuccess', roles);
                    return Promise.resolve(roles);
                },
                    async error => {
                        commit('getAllFailure');
                        return Promise.reject(error);
                    })
        },

    },
    mutations: {
        getAllSuccess(state, roles) {
            state.role_list = roles;
            localStorage.setItem('roles', JSON.stringify(roles));
        },
        getAllFailure() {
            console.log('get all roles failure')
        },


    }
};