import BACKEND_URL from './api.service.js';

class RoleService {
  getAllRoles() {
    return BACKEND_URL.get(`/api/v1/get/roles`,)
      .then((response) => {
        //console.log('role service ', response.data)
        return response?.data?.roles
      });
  }
}

export default new RoleService();