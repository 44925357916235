<template>
  <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
    <v-card-title class="text-button"> User Guide </v-card-title>
    <v-row dense>
      <userGuide></userGuide>
    </v-row>
  </v-card>
</template>

<script>
import userGuide from '@/components/userGuide.vue';
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "HomeView",
  components: {
    userGuide,
  },
  setup() {


    return {

    };
  },

  data: () => ({
    content: "",
  }),
  mounted() {
    UserService.getAllAccessBoard().then(
      response => {
        console.log(response)
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {

          EventBus.dispatch("logout");
        }
      }
    );
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

  },

  watch: {

  },

  methods: {

  },
};
</script>

<style></style>
