<template>
    <v-col cols="12" align-self="center" class="text-center ">
        <v-row no-gutters align="center" justify="center" style="height: 700px;">
            under maintenance
        </v-row>
    </v-col>

    <!-- <v-card :elevation="checkEmpty(this.logs) == 0 ? 0 : 8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
        <v-row dense>
            <v-col cols="12">
                <v-progress-linear :active="load(this.logs)" :indeterminate="loading" bottom
                    color="indigo-darken-4"></v-progress-linear>
                <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
                    <v-tab :value="1">Messages</v-tab>
                    <v-tab :value="2">Actions</v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item v-for="n in 2" :key="n" :value="n">
                        <v-container fluid>
                            <v-row>
                                <v-col v-for="i in 6" :key="i" cols="12" md="4">
                                    <v-img :src="`https://picsum.photos/500/300?image=${i * n * 5 + 10}`"
                                        :lazy-src="`https://picsum.photos/10/6?image=${i * n * 5 + 10}`"
                                        aspect-ratio="1"></v-img>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-window-item>
                </v-window>

            </v-col>

        </v-row>
    </v-card> -->
</template>
  
<script>
//import { useStore } from 'vuex'
//import { computed } from 'vue'
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";


export default {
    name: 'LogsView',
    setup() {
        //const store = useStore()
        //const user = computed(() => store.state.users.)
        //const logs = computed(() => store.state.logs.data)


        return {
            //user,
            //logs,
        }
    },
    data: () => ({
        selected: [],
        tab: null,
        logs: [],
        loading: false,
        search: "",
        content: "",
    }),
    mounted() {
        UserService.getManagerBoard().then(
            response => {
                console.log(response)
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {

                    EventBus.dispatch("logout");
                }
            }
        );
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        filtredLogs() {
            const search = this.search.toLowerCase()

            if (!search) return this.logs

            return this.logs.filter(item => {
                const user = item.user.toLowerCase()
                return user.indexOf(search) > -1
            })
        },


    },


    watch: {
        selected() {
            this.search = ''
        },
    },

    methods: {
        timeFormat(time) {
            const Time = new Date(time)
            return Time.toLocaleString('ru-RU')
        },
        checkEmpty(logs) {
            if (!logs) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
            if (logs)
                return logs.length
            return 0
        },
        load(val) {
            if (!val) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
        },
    },

};
</script>
  
<style></style>