<template>
  <div>
    <v-img class="mx-auto my-6" max-width="228"></v-img>

    <v-form fast-fail @submit.prevent v-model="isFormValid">
      <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
        <div class="text-subtitle-1 text-medium-emphasis">Account</div>

        <v-text-field density="compact" autocomplete="username" placeholder="Username" prepend-inner-icon="mdi-at"
          variant="outlined" v-model="username" :rules="usernameRules"></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
          Password
        </div>

        <v-text-field :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'"
          autocomplete="current-password" density="compact" placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline" variant="outlined" v-model="password" :rules="passwordRules"
          @click:append-inner="visible = !visible"></v-text-field>

        <v-card class="mb-12" color="surface-variant" variant="tonal">
          <v-card-text class="text-medium-emphasis text-caption">
            {{
              message ||
              "Warning: If you don't have account, please contact to Administrator"
            }}
          </v-card-text>
        </v-card>

        <v-btn block :disabled="!isFormValid" type="submit" class="mb-8" color="indigo-darken-2" size="large"
          variant="tonal" @click="handleLogin(username, password)">
          Log In
        </v-btn>
      </v-card>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "SignInView",
  data: () => ({
    visible: false,
    message: "",
    username: "",
    isFormValid: false,
    usernameRules: [
      (value) => {
        if (value?.length != 0) {
          return true;
        }

        return "Username can't be empty.";
      },
    ],
    password: "",
    passwordRules: [
      (value) => {
        if (value?.length > 6) {
          return true;
        }

        return "Password must contain at lesat 6 characters.";
      },
    ],
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/sms");
    }
    this.getTgUsername()
  },
  methods: {
    getTgUsername() {
      const tg = window.Telegram.WebApp
      this.username = tg.initDataUnsafe?.user?.username
    },
    handleLogin() {
      const user = { username: this.username, password: this.password };
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
