<template>
  <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
    <v-card-title class="text-button"> Bank </v-card-title>
    <v-row dense>
      <!-- <SimTableMobile v-if="isMobile()"></SimTableMobile>
      <SimTableMobile v-else></SimTableMobile> -->
      <SimTableMobile></SimTableMobile>
    </v-row>
  </v-card>
</template>

<script>

import SimTableMobile from '@/components/SimTableMobile.vue';
//import SimTableDesktop from '@/components/SimTableDesktop.vue';
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";


export default {
  name: "BankView",
  components: {
    SimTableMobile,
    //SimTableDesktop
  },
  setup() {

    return {

    };
  },
  data: () => ({
    loading: false,
    mobile: false,
    content: ""
  }),
  mounted() {
    UserService.getSimManagerBoard().then(
      response => {
        console.log(response)
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {

          EventBus.dispatch("logout");
        }
      }
    );
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

  },

  watch: {

  },

  methods: {
    load(val) {
      if (!val) this.loading = true;
      setTimeout(() => (this.loading = false), 1000);
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log(navigator.userAgent)
        return true
      } else {
        console.log(navigator.userAgent)
        return false
      }
    }
  },
};
</script>

<style></style>
