import BACKEND_URL from './api.service.js';

class UserService {
  getAllSessions() {
    return BACKEND_URL.get(`/api/v1/get/sessions`)
      .then((response) => {
        //console.log('line service ', response.data)
        return response?.data?.sessions
      });
  }

  getAllUserSessions(username) {
    return BACKEND_URL.post(`/api/v1/get/usersessions`, {
      username: username,
    })
      .then((response) => {
       //console.log('line service ', response.data)
        return response?.data?.sessions
      });
  }

  killSession(task_id) {
    return BACKEND_URL.post(`/api/v1/kill/session`, {
      task_id: task_id,
    })
      .then((response) => {
        //console.log('line service ', response.data)
        return response?.data?.message
      });
  }

  reloadModule(line_id) {
    return BACKEND_URL.post(`/api/v1/reload/module`, {
      line_id: line_id,
    })
      .then((response) => {
        //console.log('line service ', response.data)
        return response?.data?.message
      });
  }
  wakeupSim(sim_id) {
    return BACKEND_URL.post(`/api/v1/wakeup/sim`, {
      sim_id: sim_id,
    })
      .then((response) => {
        //console.log('line service ', response.data)
        return response?.data?.message
      });
  }
  requestSession(data) {
    return BACKEND_URL.post(`/api/v1/start/session`, {
      username: data.username,
      tg_userid: data.tg_userid,
      payload: data.payload,
    })
      .then((response) => {
        //console.log('line service ', response.data)
        return response?.data
      });
  }
}

export default new UserService();