<template>
  <v-col cols="12" align-self="center" class="text-center ">
    <v-row no-gutters align="center" justify="center" style="height: 700px;">
      under maintenance
    </v-row>
  </v-col>
</template>

<script>
//import { useStore } from "vuex";
//import { computed } from "vue";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "DropsView",
  setup() {
    //const store = useStore();
    //const messages = computed(() => store.state.local.api.messages);
    //const profiles = computed(() => store.state.local.api.profiles);
    //console.log(store.state.local.api.selected_group)


    return {
      //profiles,
      //messages,
    };
  },
  mounted() {
    UserService.getRegistratorBoard().then(
      response => {
        console.log(response)
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {

          EventBus.dispatch("logout");
        }
      }
    );
  },
  data: () => ({
    selected: [],
    loading: false,
    search: "",
    content: "",
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    filtredProfiles() {
      const search = this.search.toLowerCase();

      if (!search) return this.profiles;

      return this.profiles.filter((item) => {
        const output = item.name.toLowerCase();

        return output.indexOf(search) > -1;
      });
    },

  },

  watch: {
    selected() {
      this.search = "";
    },
  },

  methods: {
    start(profile_id) {
      console.log(profile_id);
      this.$store.dispatch("local/startProfile", profile_id);
    },
    launch(data) {
      const workerData = {
        name: data.name,
        id: data.user_id,
        headless: false,
        wallet: data.wallet,
        //username: 0,
        password: "notfound", //check
      };

      this.$store.dispatch("local/launchWallet", workerData);
    },
    filtredMessages(ProfileName) {
      const search = ProfileName.toLowerCase();

      //if (!search) return this.profiles

      return this.messages.filter((item) => {
        const output = item.name.toLowerCase();

        return output.indexOf(search) > -1;
      });
    },
    load(val) {
      if (!val) this.loading = true;
      setTimeout(() => (this.loading = false), 1000);
    },
  },
};
</script>

<style></style>
