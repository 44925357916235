import { createStore } from 'vuex'
import { auth } from './modules/auth.module.js'
import { users } from './modules/user.module.js'
import { roles } from './modules/role.module.js'
import { teams } from './modules/team.module.js'
import { sessions } from './modules/line.module.js'
import { messages } from './modules/message.module.js'
import { bank } from './modules/bank.module.js'
const store = createStore({
  modules: {
    auth,
    users,
    roles,
    teams,
    sessions,
    messages,
    bank
  },

})



export default store;