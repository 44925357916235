import BACKEND_URL from './api.service.js';

class TeamService {
  getAllTeams() {
    return BACKEND_URL.get(`/api/v1/get/teams`)
      .then((response) => {
        //console.log('team service ', response.data)
        return response?.data?.teams
      });
  }

  addTeam(name) {
    return BACKEND_URL.post('/api/v1/add/team', {
      name: name
    })
      .then((response) => {
        //console.log('team service ', response.data)
        return response?.data
      });
  }

  deleteTeam(name) {
    return BACKEND_URL.post('/api/v1/delete/team', {
        name: name,
      })
      .then((response) => {
        //console.log('team service ', response.data)
        return response?.data
      });

  }
}

export default new TeamService();