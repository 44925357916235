<template>
  <!-- <v-container> -->
  <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
    <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
    <v-row dense>
      <v-col cols="12">
        <v-progress-linear :active="load(this.users)" :indeterminate="loading" bottom
          color="indigo-darken-4"></v-progress-linear>
      </v-col>
      <v-col v-for="user in filtredUsers" :key="user.username" cols="12">
        <v-card elevation="3">

          <v-card-title class="text-overline">
            <v-row justify="end" align="center">
              <v-col class="text-left pb-2" cols="6">
                {{ user.username }}
              </v-col>
              <v-col class="text-right pb-2" cols="6">
                <v-btn :append-icon="user.show ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="indigo-darken-2"
                  variant="text" @click="user.show = !user.show">
                  Show profile
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider class="mx-4"></v-divider>

          <v-expand-transition>
            <div v-show="user.show">

              <v-card-text hide-details class="pt-0">

                <v-row justify="end" align="center">
                  <v-col class="text-left pb-2" cols="6">
                    <v-switch v-model="user.enable" color="purple" label="Enable" hide-details></v-switch>
                  </v-col>
                  <v-col class="text-right pb-2" cols="6">
                    <v-btn color="red-darken-4" variant="text" @click="this.delete(user.username)">
                      Delete User
                    </v-btn>
                  </v-col>
                </v-row>

                <h3 class="mb-1">Choose roles</h3>

                <v-chip-group class="mb-1 mt-1" v-model="user.roles" mandatory column multiple
                  color="deep-purple-accent-4">
                  <v-chip hide-details v-for="role in allRoles" :key="role.name" cols="12" filter variant="outlined"
                    :text="role.name" :value="role.name">
                  </v-chip>
                </v-chip-group>

                <h3 class="mb-1">Choose teams</h3>

                <v-chip-group class="mb-1 mt-1" v-model="user.teams" mandatory column multiple
                  color="deep-purple-accent-4">
                  <v-chip hide-details v-for="team in allTeams" :key="team.name" cols="12" filter variant="outlined"
                    :text="team.name" :value="team.name">
                  </v-chip>
                </v-chip-group>

              </v-card-text>

            </div>
          </v-expand-transition>

          <v-card-actions class="justify-center">
            <v-btn block variant="tonal" color="indigo-darken-2" @click="
              update({
                username: user.username,
                enable: user.enable,
                roles: user.roles,
                teams: user.teams,
              })
              ">
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
  <!-- </v-container> -->
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "UsersView",
  setup() {
    const store = useStore();

    store.dispatch("users/getAll");
    store.dispatch("roles/getAll");
    store.dispatch("teams/getAll");

    const users = computed(() => store.state.users.user_list);
    const allRoles = computed(() => store.state.roles.role_list);
    const allTeams = computed(() => store.state.teams.team_list);
    return {
      users,
      allRoles,
      allTeams,
    };
  },
  mounted() {
    UserService.getAdminBoard().then(
      response => {
        console.log(response)
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {

          EventBus.dispatch("logout");
        }
      }
    );
  },

  data: () => ({
    selected: [],
    loading: false,
    content: "",
    search: "",
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    filtredUsers() {
      const search = this.search.toLowerCase();

      if (!search) return this.users;

      return this.users.filter((item) => {
        const output = item.username.toLowerCase();
        return output.indexOf(search) > -1;
      });
    },
  },

  watch: {
    selected() {
      this.search = "";
    },
  },

  methods: {
    load(val) {
      if (!val) this.loading = true;
      setTimeout(() => (this.loading = false), 1000);
    },
    update(payload) {
      this.$store.dispatch("users/update", payload);
    },
    delete(payload) {
      this.$store.dispatch("users/delete", payload);
    }
  },
};
</script>

<style></style>
