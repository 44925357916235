import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SignInView from '../views/SignInView.vue';
import AdminView from '../views/AdminView.vue';
import ProfileView from '../views/ProfileView.vue';
import LogsView from '../views/LogsView.vue';
import SmsView from '../views/SmsView.vue';
import DropsView from '../views/DropsView.vue';
import BankView from '../views/BankView.vue';
import UsersView from '../views/UsersView.vue';
import LinesView from '../views/LinesView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/sms',
    name: 'sms',
    component: SmsView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/drops',
    name: 'drops',
    component: DropsView
  },
  {
    path: '/bank',
    name: 'bank',
    component: BankView
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView
  },
  {
    path: '/lines',
    name: 'lines',
    component: LinesView
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),//web
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/signin'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/signin');
  } else {
    next();
  }
});

export default router
