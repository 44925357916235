import bankService from '@/services/bank.service';


const storagedBank = JSON.parse(localStorage.getItem('bank') || null);
const initialState = {
    bank_list: storagedBank
}

export const bank = {
    namespaced: true,
    state: initialState,
    actions: {
        getAll({ commit }) {
            return bankService.getAllBanks()
                .then(async banks => {
                    //console.log('bank module ', banks)
                    commit('getAllSuccess', banks);
                    return Promise.resolve(banks);
                },
                    async error => {
                        commit('getAllFailure');
                        return Promise.reject(error);
                    })
        },
        link({ commit }, payload) {
            return bankService.linkSIm(payload)
                .then(async response => {
                    //console.log('bank module ', response)
                    commit('linkSuccess');
                    return Promise.resolve(response);
                },
                    async error => {
                        commit('linkFailure');
                        return Promise.reject(error);
                    })
        },
        unlink({ commit }, payload) {
            return bankService.unlinkSIm(payload)
                .then(async response => {
                    //console.log('bank module ', response)
                    commit('unlinkSuccess');
                    return Promise.resolve(response);
                },
                    async error => {
                        commit('unlinkFailure');
                        return Promise.reject(error);
                    })
        },


    },
    mutations: {
        getAllSuccess(state, banks) {
            state.bank_list = banks;
            localStorage.setItem('bank', JSON.stringify(banks));
        },
        getAllFailure() {
            console.log('get all banks failure')
        },
        linkSuccess() {
            console.log('link success')
            //Object.assign(state.bank_list.find(bank => bank.username === payload.username), payload);
        },
        linkFailure() {
            console.log('link simcard failure')
        },
        unlinkSuccess() {
            console.log('unlink success')
            //Object.assign(state.bank_list.find(bank => bank.username === payload.username), payload);
        },
        unlinkFailure() {
            console.log('unlink simcard failure')
        },
    },

};
