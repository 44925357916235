<template>
  <v-container>
    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
      <v-row dense>
        <v-col cols="12">
          <v-card elevation="3">
            <v-card-title class="text-button">
              <v-form>
                <v-row justify="end">
                  <v-col> Create user </v-col>
                </v-row>
              </v-form>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-col cols="12" class="px-6">
              <v-form fast-fail @submit.prevent v-model="isFormValid">
                <div class="text-subtitle-1 text-medium-emphasis">Account</div>

                <v-text-field autocomplete="username" density="compact" placeholder="Username" prepend-inner-icon="mdi-at"
                  variant="outlined" v-model="username" :rules="usernameRules"></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                  Password
                </div>

                <v-text-field autocomplete="current-password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible ? 'text' : 'password'" density="compact" placeholder="Enter your password"
                  prepend-inner-icon="mdi-lock-outline" variant="outlined" v-model="password" :rules="passwordRules"
                  @click:append-inner="visible = !visible"></v-text-field>
              </v-form>

              <v-dialog v-model="dialog" :scrim="false" persistent width="auto">
                <v-card elevation="6">
                  <v-card-text>
                    {{ this.message }}
                    <v-progress-linear indeterminate color="indigo-darken-2" class="mb-0"></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>

            <v-card-actions class="justify-center">
              <v-btn :disabled="!isFormValid" type="submit" variant="text" color="indigo-darken-2" :loading="dialog"
                @click="handleSignup(); dialog = true">
                create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "AdminView",
  data() {
    return {
      message: '',
      visible: false,
      dialog: false,
      isFormValid: false,
      username: "",
      password: "",
      content: "",
      usernameRules: [
        (value) => {
          if (value?.length != 0) {
            return true;
          }
          return "Username can't be empty.";
        },
      ],

      passwordRules: [
        (value) => {
          if (value?.length > 6) {
            return true;
          }
          return "Password must contain at lesat 6 characters.";
        },
      ],
    };
  },
  mounted() {
    UserService.getAdminBoard().then(
      response => {
        console.log(response)
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

  },
  watch: {
    dialog(val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 3000)
    },
  },
  methods: {
    handleSignup() {
      const user = {
        telegram: this.username,
        username: this.username,
        password: this.password,
        roles: ["user"],
        teams: ["none"]
      };
      this.$store.dispatch("auth/createUser", user).then((response) => {
        this.message = response.message
      }, (error) => {
        this.message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
      }
      );
    },
  },
};
</script>

<style scoped></style>
