import { constants } from '../config/default.js';
import axios from 'axios';

const BACKEND_URL = axios.create({
    baseURL: `${constants.BACKEND_URL}:${constants.BACKEND_PORT}`,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
});

export default BACKEND_URL;