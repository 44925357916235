<template>
  <v-app-bar :elevation="8" color="black" class="flex-grow-0" app dark>
    <template v-slot:image><v-img gradient="to top right, rgba(46, 0, 56,.8), rgba(9, 67, 98, 5.95)"></v-img></template>
    <v-app-bar-nav-icon v-if="loggedIn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-app-bar-title>sms-service</v-app-bar-title>
    <v-btn icon="mdi-refresh" @click="this.$router.go()"></v-btn>
    <v-btn icon="mdi-logout-variant" @click="logOut()"></v-btn>
  </v-app-bar>

  <v-navigation-drawer v-if="loggedIn" :elevation="8" app v-model="drawer">
    <v-list-item>
      <v-list-item-title class="text-h6"> {{ currentUser?.username }} </v-list-item-title>
      <v-list-item-subtitle> </v-list-item-subtitle>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense nav>

      <div v-for="item in user_routes" :key="item.title">
        <v-list-item v-if="showUserBoard" :to="item.route" :title="item.title">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </div>

      <div v-for="item in default_routes" :key="item.title">
        <v-list-item :to="item.route" :title="item.title">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </div>

      <div v-for="item in registrator_routes" :key="item.title">
        <v-list-item v-if="showRegistratorBoard" :to="item.route" :title="item.title">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </div>

      <div v-for="item in bank_routes" :key="item.title">
        <v-list-item v-if="showBankBoard" :to="item.route" :title="item.title">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </div>

      <div v-for="item in manager_routes" :key="item.title">
        <v-list-item v-if="showManagerBoard" :to="item.route" :title="item.title">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </div>

      <div v-for="item in admin_routes" :key="item.title">
        <v-list-item v-if="showAdminBoard" :to="item.route" :title="item.title">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </div>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-list-item-subtitle>build: {{ appVersion }} </v-list-item-subtitle>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
//import { remote } from "electron";
export default {
  name: "AppNavigation",
  setup() {
    const appVersion = require("../../package.json").version;
    return {
      appVersion,
    };
  },
  //side nav-bar buttonst
  data: () => ({
    drawer: true,
    default_routes: [
      {
        title: "Profile",
        icon: "mdi-text-account",
        route: "/Profile",
      },
    ],
    user_routes: [
      {
        title: "Sms",
        icon: "mdi-view-list",
        route: "/sms",
      },
    ],
    registrator_routes: [
      {
        title: "Drops",
        icon: "mdi-crop-free",
        route: "/drops",
      },
    ],
    bank_routes: [
      {
        title: "Bank",
        icon: "mdi-sim-outline",
        route: "/bank",
      },
    ],
    manager_routes: [
      {
        title: "Logs",
        icon: "mdi-chart-donut",
        route: "/logs",
      },
      {
        title: "Lines",
        icon: "mdi-chart-line",
        route: "/lines",
      },
    ],
    admin_routes: [
      {
        title: "Users",
        icon: "mdi-account-box-outline",
        route: "/users",
      },
      {
        title: "Admin",
        icon: "mdi-view-dashboard-outline",
        route: "/admin",
      },
    ],
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    showManagerBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_MANAGER");
      }

      return false;
    },
    showBankBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_SIM-MANAGER");
      }

      return false;
    },
    showRegistratorBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_REGISTRATOR");
      }

      return false;
    },
    showUserBoard() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_USER");
      }

      return false;
    },

  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/signin");
    },
  },
};
</script>

<style>
.v-image .v-responsive__content {
  max-width: 100%;
}
</style>
