<template>
  <v-app id="app">
    <app-navigation></app-navigation>

    <div>
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </div>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import AppNavigation from "@/components/AppNavigation";
import EventBus from "./common/EventBus";

export default {
  name: "App",
  components: {
    AppNavigation,
  },
  setup() {

  },
  created() {
    this.tgExpand();
    this.tgLogOutUserMissmatch();
  },

  async beforeCreate() {

  },
  computed: {

  },
  methods: {
    tgExpand() {
      try {
        const tg = window.Telegram.WebApp; //получаем объект webapp телеграма
        tg.ready()
        tg.expand(); //расширяем на все окно  
      } catch (error) {
        console.log(error)
      }
    },
    tgLogOutUserMissmatch() {
      try {
        const tg = window.Telegram.WebApp; //получаем объект webapp телеграма
        let webUser = this.currentUser()
        let tgUser = tg.initDataUnsafe?.user
        if (webUser && tgUser) {
          console.log(webUser)
          console.log(tgUser)

          if (tgUser?.username.toLowerCase() != webUser?.username.toLowerCase())
            this.logOut();
        }
      } catch (error) {
        console.log(error)
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/signin');
    },
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  },
};
</script>

<style></style>
