import messageService from "@/services/message.service"; '../../services/message.service.js';

const storagedMessages = JSON.parse(localStorage.getItem('messages') || null);
const initialState = {
  list: storagedMessages
}

export const messages = {
  namespaced: true,
  state: initialState,
  actions: {
    getAll({ commit }, username) {
      return messageService.getUserMessages(username)
        .then(async messages => {
          //console.log('message module ', messages)
          commit('getAllSuccess', messages);
          return Promise.resolve(messages);
        },
          async error => {
            commit('getAllFailure');
            return Promise.reject(error);
          })
    },

  },
  mutations: {
    getAllSuccess(state, messages) {
      state.list = messages;
      localStorage.setItem('messages', JSON.stringify(messages));
    },
    getAllFailure() {
      //console.log('get all messages failure')
    },

  }
};