import BACKEND_URL from './api.service.js';
import TokenService from "./token.service.js";

class AuthService {
  login(user) {
    return BACKEND_URL.post('/api/auth/signin', {
      username: user.username,
      password: user.password
    })
      .then(response => {
        if (response.data.accessToken) {
          //localStorage.setItem('user', JSON.stringify(response.data));
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  refreshClientData(username) {
    return BACKEND_URL.post('/api/auth/refreshclient', {
      username: username
    })
      .then((response) => {
        if (response.data.accessToken) {
          //localStorage.setItem('user', JSON.stringify(response.data));
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    //localStorage.removeItem('user');
    TokenService.removeUser();
  }

  createUser(user) {
    return BACKEND_URL.post('/api/auth/createuser', {
      ...user
    }).then(response => {
      /* if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
        
      } */
      return response.data;
    });
  }
}

export default new AuthService();