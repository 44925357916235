import BACKEND_URL from './api.service.js';

class UserService {
  getAllBanks() {
    return BACKEND_URL.get(`/api/v1/get/banktable`)
      .then((response) => {
        //console.log('bank service ', response.data)
        return response?.data?.bank
      });
  }

  linkSIm(payload) {
    //console.log(payload)
    return BACKEND_URL.post('/api/v1/set/link', {
      id: payload.id,
      fixed_simnum: payload.fixed_simnum,
      drops: payload.drops,
    })
      .then((response) => {
        //console.log('bank service ', response.data)
        return response?.data
      });
  }

  unlinkSIm(payload) {
    //console.log(payload)
    return BACKEND_URL.post('/api/v1/set/unlink', {
      id: payload.id,
    })
      .then((response) => {
        //console.log('bank service ', response.data)
        return response?.data
      });
  }

}

export default new UserService();