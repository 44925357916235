<template>
    <!--  <v-container fill-height> -->

    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">

        <v-card-title class="text-button"> Info </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text class="font-weight-bold">
            <p> Telegram: @{{ user?.telegram }}</p>
            <p> Sms received: [[ - ]]</p>

            <div class="mt-5 mb-2 mx-auto">
                <p>Roles:</p>
                <v-chip v-for="role in user?.roles" :key="role" class="font-weight-bold ma-1" size="small"
                    color="indigo-darken-2" variant="outlined">
                    {{ role.toLowerCase().slice(5) }}
                </v-chip>
                <p>Teams:</p>
                <v-chip v-for="team in user?.teams" :key="team" class="font-weight-bold ma-1" color="success" size="small"
                    variant="outlined">
                    {{ team.toLowerCase().slice(5) }}
                </v-chip>
            </div>
        </v-card-text>
    </v-card>

    <v-card :elevation="checkEmpty(this.sessions) == 0 ? 0 : 8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
        <v-row dense>
            <v-col cols="12">
                <v-progress-linear :active="load(this.sessions)" :indeterminate="loading" bottom
                    color="indigo-darken-4"></v-progress-linear>
            </v-col>
            <v-col cols="12" v-if="checkEmpty(this.sessions) == 0" align-self="center" class="text-center ">

                <v-row no-gutters align="center" justify="center" style="height: 300px;">
                    no active sessions yet
                </v-row>

            </v-col>
            <v-col v-for="session in filtredSessions" :key="session.fixed_simnum" cols="12">
                <v-card elevation="3">

                    <v-card-title class="text-overline">
                        <v-row justify="end" align="center">
                            <v-col class="text-left pb-2" cols="6">
                                <!-- <v-icon :color="online ? 'success' : 'red-darken-2'" icon="mdi-circle-medium"></v-icon> -->
                                {{ session.fixed_simnum }} {{ session.drops ? `[${session.drops}]` : `` }}
                            </v-col>
                            <v-col class="text-right pb-2" cols="6">
                                <v-btn :append-icon="session.show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                                    color="indigo-darken-2" variant="text" @click="session.show = !session.show">
                                    Details
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-divider class="mx-4"></v-divider>

                    <v-expand-transition>
                        <div v-show="session.show">

                            <div class="pt-2 px-4 d-flex align-center">
                                <v-icon :color="session.gsm_status == 'login' ? 'success' : 'red-darken-2'"
                                    icon="mdi-circle-medium"></v-icon>
                                <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                    {{ session.gsm_status }} {{ session.pro ? `[${session.pro}]` : `` }}
                                </span>

                                <v-spacer></v-spacer>

                                <v-btn color="indigo-darken-2" class="text-button" variant="text"
                                    @click="reload(session.line_id)">
                                    reload
                                </v-btn>
                                <v-btn color="indigo-darken-2" class="text-button" variant="text"
                                    @click="wakeup(session.sim_id)">
                                    wakeup
                                </v-btn>
                            </div>
                            <v-row no-gutters class="pt-2 px-4 d-flex align-center">
                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-account-box-outline"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        user: {{ session.current_user }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-signal"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        signal: {{ session.signal }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        session id: {{ session.task_id }}
                                    </span>
                                </v-col>

                                <!-- <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        line id: {{ session.line_id.slice(-2) }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        bank id: {{ session.sim_id.slice(0, 3) }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        sim id: {{ session.sim_id.slice(-3) }}
                                    </span>
                                </v-col> -->

                            </v-row>

                        </div>
                    </v-expand-transition>

                    <v-card-actions class="justify-center">
                        <v-btn block variant="tonal" color="indigo-darken-2" @click="kill(session.task_id)">
                            cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-card>

    <!--  </v-container> -->
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
    name: "ProfileView",
    setup() {
        const store = useStore();
        const user = computed(() => store.state.auth.user);
        store.dispatch("sessions/getAllPerUser", user.value.username);

        const sessions = computed(() => store.state.sessions.per_user);
        return {
            sessions,
            user
        };
    },
    data: () => ({
        show: false,
        loading: false,
        search: "",
        content: "",
    }),
    mounted() {
        UserService.getAllAccessBoard().then(
            response => {
                console.log(response)
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {

                    EventBus.dispatch("logout");
                }
            }
        );
    },


    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        filtredSessions() {
            const search = this.search.toLowerCase();

            if (!search) return this.sessions;

            return this.sessions.filter((item) => {
                const output = item.fixed_simnum.toLowerCase();
                return output.indexOf(search) > -1;
            });
        },
    },
    methods: {
        load(val) {
            if (!val) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
        },
        kill(task_id) {
            this.$store.dispatch("sessions/killPerUser", task_id);
        },
        wakeup(sim_id) {
            this.$store.dispatch("sessions/wakeup", sim_id);
        },
        reload(line_id) {
            this.$store.dispatch("sessions/reload", line_id);
        },
        checkEmpty(sessions) {
            if (!sessions) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
            if (sessions)
                return sessions.length
            return 0
        },
    },
    watch: {

    }
};
</script>

<style scoped></style>
