
import BACKEND_URL from './api.service.js';

class UserService {

  getAllAccessBoard() {
    return BACKEND_URL.get('/api/v1/access/all')
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  getUserBoard() {
    return BACKEND_URL.get('/api/v1/access/user')
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  getRegistratorBoard() {
    return BACKEND_URL.get('/api/v1/access/reg')
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  getManagerBoard() {
    return BACKEND_URL.get('/api/v1/access/manager')
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  getSimManagerBoard() {
    return BACKEND_URL.get('/api/v1/access/sim-manager')
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  getAdminBoard() {
    return BACKEND_URL.get('/api/v1/access/admin')
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  getAllUsers() {
    return BACKEND_URL.get(`/api/v1/get/users`)
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data?.users
      });
  }

  updateUser(payload) {
    return BACKEND_URL.post('/api/v1/update/user', {
      username: payload.username,
      roles: payload.roles,
      teams: payload.teams,
      enable: payload.enable
    })
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });
  }

  deleteUser(username) {
    return BACKEND_URL.post('/api/v1/delete/user', {
      username: username,
    })
      .then((response) => {
        //console.log('user service ', response.data)
        return response?.data
      });

  }


}

export default new UserService();