<template>
    <!-- <v-container fill-height> -->

    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <v-card-title class="text-button"> Request session </v-card-title>
        <v-form fast-fail @submit.prevent>
            <v-card-text>
                <v-text-field v-model="requestSession" label="Number or drop id" :loading="loading" density="compact"
                    variant="outlined" single-line hide-details>
                    <template v-slot:append-inner>
                        <v-btn :loading="dialog" type="submit" template="append" color="success" variant="text"
                            @click="handleRequest(); dialog = true"> Request </v-btn>
                    </template>
                </v-text-field>
            </v-card-text>
        </v-form>

        <v-dialog v-model="dialog" :scrim="false" persistent width="auto">
            <v-card elevation="6">
                <v-card-text>
                    {{ this.message }}
                    <v-progress-linear indeterminate color="indigo-darken-2" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>

    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <v-card-title class="text-button"> Messages </v-card-title>
        <v-card-text class="px-4 pb-0">
            <v-text-field label="Search" v-model="search" density="compact" variant="outlined" single-line
                hide-details></v-text-field>
        </v-card-text>
        <v-card-actions class="px-4">
            <div class="d-flex align-center">Latest 100 messages: </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <v-btn :append-icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="indigo-darken-2" variant="text"
                    @click="show = !show"> Show </v-btn>
            </div>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="show">
                <v-container fluid>
                    <v-row dense>
                        <v-col v-for=" item  in  filtredMessages " :key="item.id" cols="12">
                            <v-card elevation="2">
                                <v-card-title class="text-button">
                                    {{ item?.simnum }} {{ item?.drops ? `[${item?.drops}]` : `` }}
                                </v-card-title>
                                <v-divider class="mx-4"></v-divider>
                                <v-card-subtitle class="mt-1">From: {{ item?.srcnum }}</v-card-subtitle>
                                <v-card-text>
                                    <p class="py-2">
                                        {{ item?.message }}
                                    </p>
                                    <v-divider></v-divider>
                                    <p class="py-2">
                                        received at: {{ timeFormat(item?.createdAt) }}
                                    </p>
                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-expand-transition>

    </v-card>



    <!--  </v-container> -->
</template>
  
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";


export default {
    name: 'SmsView',
    setup() {
        const store = useStore()
        const user = computed(() => store.state.auth.user);

        store.dispatch("messages/getAll", user.value.username);

        const messages = computed(() => store.state.messages.list)
        return {
            messages,
        }
    },
    mounted() {
        UserService.getUserBoard().then(
            response => {
                console.log(response)
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {

                    EventBus.dispatch("logout");
                }
            }
        );
    },
    data: () => ({
        selected: [],
        search: '',
        message: '',
        requestSession: '',
        dialog: false,
        loading: false,
        show: true,
    }),


    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        filtredMessages() {
            const search = this.search.toLowerCase()

            if (!search) return this.messages

            return this.messages.filter(item => {
                const message = item.simnum.toLowerCase()
                return message.indexOf(search) > -1
            })
        },


    },


    watch: {
        selected() {
            this.search = ''
        },
        dialog(val) {
            if (!val) return
            setTimeout(() => (this.dialog = false), 3000)
        },
    },

    methods: {
        timeFormat(time) {
            const Time = new Date(time)
            return Time.toLocaleString('ru-RU')
        },
        handleRequest() {
            const user = this.currentUser
            const data = {
                username: user.username,
                tg_userid: user.tg_userid,
                payload: this.requestSession,
            };
            this.$store.dispatch("sessions/request", data).then((response) => {
                this.message = response.message
            }, (error) => {
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
            );
        },
    },

};
</script>
  
<style></style>